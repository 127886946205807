import React from "react";

const Navbar = () => {
  return (
    <div className="lg:mx-auto px-10 xl:px-44 md:px-16">
      <nav className="nav px-2">
        <input type="checkbox" id="nav-check" />

        <div className="nav-btn">
          <label for="nav-check" className="w-[100%] text-center">
            <img
              className="w-[100%]"
              src="../assets/navbar_mob_header_1.png"
              alt="https://basescape.io/"
            />
          </label>
        </div>

        <ul class="nav-list text-center font-bold pt-[-1px]">
          <li>
            <a
              className="!text-black !bg-[linear-gradient(180deg,_#FFE5AC_0%,_#F8C437_53.09%,_#F4B200_53.1%,_#F5B609_78.1%,_#FFE5AC_100%)]"
              href="#"
            >
              Home
            </a>
          </li>
          <li>
            <a href="https://twitter.com/base_scape">Twitter/X</a>
          </li>
          <li>
            <a href="https://basescape.io/">Game Guide</a>
          </li>
          <li>
            <a href="https://discord.com/invite/basescape">
              Discord
            </a>
          </li>
          <li>
            <a href="https://basescape.io/">
              Play Now
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
