import "./App.css";
import Etherscape_artical from "./components/Etherscape_artical";
import Events_featured from "./components/Events_featured";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import PlayScape_Grab from "./components/PlayScape_Grab";
import ContactUs from "./components/contactUs";

function App() {
  return (
    <div className="container mx-auto px-4 sm:px-20 lg:px-44 md:px-32">
      <Header />
      <Navbar />
      <PlayScape_Grab />
      <Events_featured />
      
    </div>
  );
}

export default App;
