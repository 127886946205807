import React, { useState } from "react";

const PlayScape_Grab = () => {
  const [isClicked, setIsClicked] = useState(false);
  const [message, setMessage] = useState(false);

  const handleClick = () => {
    setIsClicked(true);
    setMessage(true);
  };
  return (
    <div>
      <div className="lg:mx-auto px-10 xl:px-44 md:px-16 pt-2 pb-1 lg:pb-5 font-alegreya">
        <div className="grid grid-cols-12 lg:gap-2">
          <div className="col-span-12 lg:col-span-4 ">
            <div className="relative">
              <img
                src="../assets/play_etherScape_new.png"
                className="full-width-img absolute w-[100%]"
                alt=""
              />

              <div className="z-50 relative text-center lg:pt-6 pt-5">
                <h5 className="text-text-yellow-shade  md:text-3xl text-2xl">
                  <br /> 
                </h5>
                <h6 className="text-text-yellow-shade-4 uppercase text-sm">
                
                </h6>
                <a href="">
                  <img
                    src=""
                    className="full-width-img lg:pt-[5px] pt-[10px] px-5 hover:scale-105"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-span-12 lg:col-span-8">
            <div className="relative">
              <div className="desktop_view_screen">
                <img
                  className="absolute w-[100%]"
                  src="../assets/grab_token_2.png"
                  alt=""
                />
              </div>
              <div className="mobile_view_screen">
                <img
                  className="absolute"
                  src="../assets/grab_token_2_mob.png"
                  alt=""
                />
              </div>

              <div className="relative z-50">
                <h6 className="text-text-yellow-shade-4 text-center sm:pt-0 md:pt-4 lg:text-2xl xl:text-3xl text-2xl lg:pt-4 xl:pt-7 pt-7 font-semibold">
                  
                </h6>
                <p className="text-center  text-white text-sm">
                  
                </p>
                <div className="flex lg:gap-8 xl:gap-8 gap-3 md:gap-4 sm:gap-4 md:px-5 px-1 lg:px-12 xl:px-10 sm:pt-1 lg:pt-3 pt-6">
                  <div className="image-container">
                    <a
                      href=""
                      // className="block bg-[linear-gradient(180deg,_#FFE5AC_0%,_#F8C437_53.09%,_#F4B200_53.1%,_#F5B609_78.1%,_#FFE5AC_100%)]"
                    >
                      <div class="rainbow">
                        <img
                          className="hover:opacity-80"
                          src=""
                          alt=""
                        />
                      </div>
                      {/* Buy Now */}
                    </a>
                  </div>

                  <div className="visa_disable">
                    <button
                      onClick={handleClick}
                      className={
                        isClicked ? "grayscale cursor-not-allowed" : ""
                      }
                    >
                      <img
                        className="hover:opacity-80"
                        src=""
                        alt=""
                      />
                    </button>
                    <div className="font-semibold text-text-yellow-shade-4 text-center text-sm bg-black ">
                      {message == true ? "Not yet intregrated" : ""}
                    </div>
                  </div>
                  <div className="">
                    <a href="">
                      <img
                        className="hover:opacity-80"
                        src=""
                        alt=""
                      />
                    </a>
                  </div>
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayScape_Grab;
