import React, { useState } from "react";

const Etherscape_artical = () => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(true);
  };
  return (
    <div>
      <div className="lg:mx-auto px-10 xl:px-44 md:px-16  mt-2">
        <div className="grid grid-cols-12 lg:gap-2">
          <div className="col-span-12 lg:col-span-4 bg-[linear-gradient(89.84deg,_#D9D9D9_0.14%,_#544B34_0.15%,_#B1A372_2.64%,_#B1A372_96.98%,_#544B34_99.87%)] border-[2px_solid_#504730]">
            <img src="../assets/etherscape_artical_header.jpg" alt="" />
            <div className="p-1">
              <h1 className="text-text-yellow-shade-3 uppercase text-[13px] font-semibold text-center lg:text-[15px] py-1 rounded-t-lg bg-[linear-gradient(180deg,_#413B28_0%,_#5D5339_25%,_#5D5339_80.5%,_#413B28_100%)]  font-alegreya">
                
              </h1>
              <div className="bg-bg-dark-brown-2">
                <img src="" alt="" />
                <h6 className="text-[12px] lg:text-[17px]  text-text-sandal p-1 font-medium">
                  
                </h6>
                <p className="text-[13px] lg:text-[13px]  text-text-sandal px-1 pt-0 pb-2">
                 .
                </p>
              </div>
              <h1 className="text-text-yellow-shade-3 uppercase text-center text-[13px] font-semibold lg:text-[15px] py-1 rounded-t-lg bg-[linear-gradient(180deg,_#413B28_0%,_#5D5339_25%,_#5D5339_80.5%,_#413B28_100%)] mt-1 font-alegreya">
                
              </h1>
              <div className="grid grid-cols-12 lg:gap-1 bg-bg-dark-brown-2 mt-1">
                <div className="col-span-9 p-1 self-center">
                  <div className="text-[12px] lg:text-[13px] text-text-sandal font-medium pl-1">
                    
                  </div>
                  <div className="text-[12px] lg:text-[13px] pt-1 text-text-sandal pl-1">
                    
                  </div>
                </div>
                <div className="col-span-3 self-center">
                  <img
                    src=""
                    className="lg:p-1 p-3"
                    alt=""
                  />
                </div>
              </div>
              <div className="grid grid-cols-12 lg:gap-1 bg-bg-dark-brown-2 mt-1">
                <div className="col-span-9 p-1 self-center">
                  <div className="text-[12px] lg:text-[13px] text-text-sandal font-medium pl-1">
                    
                  </div>
                  <div className="text-[12px] lg:text-[13px] pt-1 text-text-sandal pl-1">
                    
                  </div>
                </div>
                <div className="col-span-3 self-center">
                  <img
                    src="./assets/moreupdate_2.jpg"
                    className="lg:p-1 p-3"
                    alt=""
                  />
                </div>
              </div>
              <div className="grid grid-cols-12 lg:gap-1 bg-bg-dark-brown-2 mt-1">
                <div className="col-span-9 p-1 self-center">
                  <div className="text-[12px] lg:text-[13px] text-text-sandal font-medium pl-1">
                    
                  </div>
                  <div className="text-[12px] lg:text-[13px] pt-1 text-text-sandal pl-1">
                    
                  </div>
                </div>
                <div className="col-span-3 self-center">
                  <img
                    src=""
                    className="lg:p-1 p-3"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12 lg:col-span-8 bg-bg-dark-brown-3">
            <div className="mobile_view_screen ">
              <img
                className="w-[100%]"
                src="../assets/recent_news_header_mob.png"
                alt=""
              />
            </div>
            <div className="desktop_view_screen">
              <img src="../assets/recent_news_header.png" alt="" />
            </div>

            <div className="recent_news_scroll">
              <div className="p-1 bg-bg-dark-brown m-1">
                <h5 className="bg-[linear-gradient(90.02deg,_#513E24_0.02%,_#392D1D_99.12%)] lg:text-[17px] text-[13px] text-text-yellow-shade-2 px-1 font-semibold">
                  
                </h5>

                <div className="grid grid-cols-12">
                  <div className="col-span-9">
                    <p className="text-text-sandal text-[15px] p-1">
                     
                    </p>
                  </div>
                  <div className="col-span-3 p-1 self-center text-center">
                    <img
                      src="../assets/recent_news_bank.png"
                      className="lg:w-[80%] h-auto m-[10px]"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="p-1 bg-bg-dark-brown m-1">
                <h5 className="bg-[linear-gradient(90.02deg,_#513E24_0.02%,_#392D1D_99.12%)] lg:text-[17px] text-[13px] text-text-yellow-shade-2 px-1 font-semibold">
                  
                </h5>

                <div className="grid grid-cols-12">
                  <div className="col-span-9">
                    <p className="text-text-sandal text-[15px] p-1">
                      
                    </p>
                  </div>
                  <div className="col-span-3 p-1 self-center">
                    <img
                      src="../assets/recent_news_axe.png"
                      className="lg:h-[100px] h-auto m-[10px]"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div className="p-1 bg-bg-dark-brown m-1">
                <h5 className="bg-[linear-gradient(90.02deg,_#513E24_0.02%,_#392D1D_99.12%)] lg:text-[17px] text-[13px] text-text-yellow-shade-2 px-1 font-semibold">
                  
                </h5>

                <div className="grid grid-cols-12">
                  <div className="col-span-12">
                    <p className="text-text-sandal text-[15px] p-1">
                      
                    </p>
                  </div>
                </div>
              </div>

              <div className="p-1 bg-bg-dark-brown m-1">
                <h5 className="bg-[linear-gradient(90.02deg,_#513E24_0.02%,_#392D1D_99.12%)] lg:text-[17px] text-[13px] text-text-yellow-shade-2 px-1 font-semibold">
                  
                </h5>

                <div className="grid grid-cols-12">
                  <div className="col-span-12">
                    <p className="text-text-sandal text-[15px] p-1">
                      
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Etherscape_artical;
