import React from "react";

const Header = () => {
  return (
    <div className="pt-">
      <div className="lg:mx-auto px-10 xl:px-44 md:px-16  ">
        <div className="flex flex-row gap-4 justify-between pb-1">
          <div className="mobile_view_screen">
            <img className="" src="../assets/Banner_with_logo_mob.png" alt="" />
          </div>
          <div className="desktop_view_screen">
            <img
              className="w-[100%]"
              src="../assets/Banner_with_logo.gif"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
